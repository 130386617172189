import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

class Feature extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <section className="section bg-light" id="features">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={8}>
                <i className="ti-clipboard title-icon text-muted"></i>
                <h3 className="title">
                  {t('Main Features')}
                </h3>
                <p className="text-muted mt-3 title-subtitle mx-auto">
                  {t('Logitruck AI offers a wide range of features designed to optimize the logistics management of your company.')}
                </p>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={4}>
                <div className="feature-box text-center p-4">
                  <div className="feature-icon mb-4">
                    <i className="ti-mobile text-primary"></i>
                  </div>
                  <h5 className="feature-title fw-bold">{t('Remote Control')}</h5>
                  <p className="feature-subtitle text-muted">
                    {t('Paperless logistics management with electronic tickets.')}
                  </p>
                </div>
              </Col>

              <Col md={4}>
                <div className="feature-box text-center p-4">
                  <div className="feature-icon mb-4">
                    <i className="ti-map-alt text-primary"></i>
                  </div>
                  <h5 className="feature-title fw-bold">{t('Real-Time Tracking')}</h5>
                  <p className="feature-subtitle text-muted">
                    {t('Monitor your shipments in real time and stay in control.')}
                  </p>
                </div>
              </Col>

              <Col md={4}>
                <div className="feature-box text-center p-4">
                  <div className="feature-icon mb-4">
                    <i className="ti-receipt text-primary"></i>
                  </div>
                  <h5 className="feature-title fw-bold">{t('Proof of Receipt and Delivery')}</h5>
                  <p className="feature-subtitle text-muted">
                    {t('Easy and quick verification of cargo at each stage.')}
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={4}>
                <div className="feature-box text-center p-4">
                  <div className="feature-icon mb-4">
                    <i className="ti-layout-media-right-alt text-primary"></i>
                  </div>
                  <h5 className="feature-title fw-bold">{t('Route Optimization')}</h5>
                  <p className="feature-subtitle text-muted">
                    {t('Improve the efficiency of your fleet with optimized routes.')}
                  </p>
                </div>
              </Col>

              <Col md={4}>
                <div className="feature-box text-center p-4">
                  <div className="feature-icon mb-4">
                    <i className="ti-money text-primary"></i>
                  </div>
                  <h5 className="feature-title fw-bold">{t('Efficient Billing')}</h5>
                  <p className="feature-subtitle text-muted">
                    {t('Optimize your billing processes for greater efficiency.')}
                  </p>
                </div>
              </Col>

              <Col md={4}>
                <div className="feature-box text-center p-4">
                  <div className="feature-icon mb-4">
                    <i className="ti-wallet text-primary"></i>
                  </div>
                  <h5 className="feature-title fw-bold">{t('Competitive Prices')}</h5>
                  <p className="feature-subtitle text-muted">
                    {t('Access competitive rates instantly.')}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Feature);