import React from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const Section = () => {
    return (
        <React.Fragment>
            <section className="section bg-home half-home" id="home">

                <div className="home-center">
                    <div className="home-desc-center">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg={10}>
                                    <Carousel className="carousel slide text-center home-carousel" id="carouselExampleIndicators"
                                        showThumbs={false} showStatus={false} showArrows={false} autoPlay infiniteLoop>
                                        <div className="item pb-5">
                                            <h1 className="text-white home-title mb-0"><span className="text-rotate">We love make things amazing and simple</span></h1>
                                            <p className="text-muted home-subtitle-center home-subtitle mt-4 mb-0 mx-auto">It is a long established fact that a reader will be of a page when established fact looking at its layout.</p>
                                            <div className="mt-4 mb-4">
                                                <Link to="#" className="btn btn-outline-white btn-round">Read More</Link>
                                            </div>
                                        </div>

                                        <div className="item pb-5">
                                            <h1 className="text-white home-title mb-0"><span className="text-rotate">Create amazing landing page with Globing</span></h1>
                                            <p className="text-muted home-subtitle-center home-subtitle mt-4 mb-0 mx-auto">It is a long established fact that a reader will be of a page when established fact looking at its layout.</p>
                                            <div className="mt-4 mb-4">
                                                <Link to="#" className="btn btn-outline-white btn-round">Read More</Link>
                                            </div>
                                        </div>

                                        <div className="item pb-5">
                                            <h1 className="text-white home-title mb-0"><span className="text-rotate">Perfact solution for small businesses</span></h1>
                                            <p className="text-muted home-subtitle-center home-subtitle mt-4 mb-0 mx-auto">It is a long established fact that a reader will be of a page when established fact looking at its layout.</p>
                                            <div className="mt-4 mb-4">
                                                <Link to="#" className="btn btn-outline-white btn-round">Read More</Link>
                                            </div>
                                        </div>
                                    </Carousel>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Section;