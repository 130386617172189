import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

class Services extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <section className="section" id="services">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={12}>
                <i className="ti-ruler-pencil title-icon text-muted"></i>
                <h3 className="title">
                  {t('Key Benefits')}
                </h3>
                <p className="text-muted mt-3 title-subtitle mx-auto">
                  {t('Discover how Logitruck AI can optimize your company’s logistics.')}
                </p>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-settings text-primary"></i>
                  </div>
                  <div className="mt-3">
                    <h5 className="services-title fw-bold mb-3">{t('Remote Control')}</h5>
                    <p className="services-subtitle text-muted">
                      {t('Paperless logistics management with electronic tickets.')}
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-palette text-primary"></i>
                  </div>
                  <div className="mt-3">
                    <h5 className="services-title fw-bold mb-3">{t('Real-Time Tracking')}</h5>
                    <p className="services-subtitle text-muted">
                      {t('Monitor your shipments in real time.')}
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-stats-up text-primary"></i>
                  </div>
                  <div className="mt-3">
                    <h5 className="services-title fw-bold mb-3">{t('Route Optimization')}</h5>
                    <p className="services-subtitle text-muted">
                      {t('Maximize the efficiency of your fleet.')}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-package text-primary"></i>
                  </div>
                  <div className="mt-3">
                    <h5 className="services-title fw-bold mb-3">{t('Proof of Receipt and Delivery')}</h5>
                    <p className="services-subtitle text-muted">
                      {t('Easy and quick verification of cargo.')}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-dashboard text-primary"></i>
                  </div>
                  <div className="mt-3">
                    <h5 className="services-title fw-bold mb-3">{t('Efficient Billing')}</h5>
                    <p className="services-subtitle text-muted">
                      {t('Optimized billing processes.')}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="services-box">
                  <div className="services-icon">
                    <i className="ti-headphone text-primary"></i>
                  </div>
                  <div className="mt-3">
                    <h5 className="services-title fw-bold mb-3">{t('Competitive Prices')}</h5>
                    <p className="services-subtitle text-muted">
                      {t('Instant access to competitive rates.')}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Services);