import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ReactTextRotator from 'react-text-rotator';

const rotatetext = [
  {
    text: "Democratizando el Transporte de Carga",
    animation: "false",
  },
  {
    text: "Accede Exclusivamente por Invitación",
    animation: "false",
  },
  {
    text: "Has sido invitado por [Nombre de la Compañía]",
    animation: "false",
  },
];

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          id: 1,
          image: "assets/images/slide-1.jpg",
        },
        {
          id: 2,
          image: "assets/images/slide-2.jpg",
        },
        {
          id: 3,
          image: "assets/images/slide-3.jpg",
        },
      ],
      currentText: rotatetext[0].text, // Keep track of the current text in the rotator
    };
  }

  handleTextChange = (index) => {
    const { invitingCompany } = this.props;
    const updatedRotatetext = rotatetext.map(item => ({
      ...item,
      text: item.text.includes("[Nombre de la Compañía]") 
            ? item.text.replace("[Nombre de la Compañía]", invitingCompany) 
            : item.text,
      animation: "false"
    }));
    this.setState({ currentText: updatedRotatetext[index].text });
  }

  render() {
    const { invitingCompany, invitingCompanyUrl } = this.props;
    const settings = {
      autoplay: true,
      infinite: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      draggable: true,
      pauseOnHover: true,
    };

    // Update the rotatetext with the actual inviting company name
    const updatedRotatetext = rotatetext.map(item => ({
      ...item,
      text: item.text.includes("[Nombre de la Compañía]") 
            ? item.text.replace("[Nombre de la Compañía]", invitingCompany) 
            : item.text,
      animation: "false"
    }));

    const slides = this.state.items.map((item, key) => {
      return (
        <section className="back-slide" id="home" key={key}>
          <img name="slide" className="slide-img" src={item.image} alt="" />
          <div className="bg-overlay"></div>
          <div className="home-center">
            <div className="home-desc-center">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={10}>
                    <div className="text-center">
                      <h1 className="home-title text-white text-rotate" id="target">
                        <ReactTextRotator
                          content={updatedRotatetext}
                          time={3000}
                          startDelay={1000}
                          onComplete={this.handleTextChange}
                        />
                      </h1>
                      <p className="text-white home-subtitle-center home-subtitle mt-4 mb-0 mx-auto">
                        Únete a la comunidad Logitruck  transforma y haz tu gesti[on eficiente
                      </p>
                      <div className="mt-4">
                        <Link to="/register" className="btn btn-outline-white btn-round">
                          Únete Ahora y gestiona tus e-tickets free
                        </Link>
                      </div>
                      {this.state.currentText.includes("Has sido invitado por") && (
                        <div className="mt-4">
                          <Link to={invitingCompanyUrl} className="btn btn-outline-white btn-round">
                            Conoce más sobre {invitingCompany}
                          </Link>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      );
    });

    return (
      <React.Fragment>
        <section className="main-slider">
          <Slider className="slides" {...settings}>
            {slides}
          </Slider>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;