import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

class Contact extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <section className="section" id="contact">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={6}>
                <i className="ti-headphone-alt title-icon text-muted"></i>
                <h3 className="title">
                  {t('Get In')} <span className="fw-bold">{t('Touch')}</span>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <div className="mt-4 pt-4">
                  <p className="text-muted mt-4">
                    <span className="fw-bold">{t('Customer care')}:</span>
                    <br /> <span className="d-block mt-2">+1 234 56 7894</span>
                  </p>
                  <p className="text-muted mt-4">
                    <span className="fw-bold">{t('Office Address')}:</span>
                    <br />{" "}
                    <span className="d-block mt-2">
                      4461 Cedar Street Moro, AR 72368
                    </span>
                  </p>
                  <p className="text-muted mt-4">
                    <span className="fw-bold">{t('Email Address')}:</span>
                    <br /> <span className="d-block mt-2">info@gmail.com</span>
                  </p>
                  <p className="text-muted mt-4">
                    <span className="fw-bold">{t('Office Time')}:</span>
                    <br />{" "}
                    <span className="d-block mt-2">9:00AM To 6:00PM</span>
                  </p>
                </div>
              </Col>
              <Col lg={8}>
                <div className="custom-form mt-4 pt-4">
                  <iframe
                    src="https://api.clixlo.com/widget/form/9KcXkinTnephPUSmxgSD"
                    style={{ width: "100%", height: "542px", border: "none", borderRadius: "4px" }}
                    id="inline-9KcXkinTnephPUSmxgSD"
                    data-layout="{'id':'INLINE'}"
                    data-trigger-type="alwaysShow"
                    data-trigger-value=""
                    data-activation-type="alwaysActivated"
                    data-activation-value=""
                    data-deactivation-type="neverDeactivate"
                    data-deactivation-value=""
                    data-form-name="Contact Form - LogiTruck"
                    data-height="542"
                    data-layout-iframe-id="inline-9KcXkinTnephPUSmxgSD"
                    data-form-id="9KcXkinTnephPUSmxgSD"
                    title="Contact Form - LogiTruck"
                  ></iframe>
                  <script src="https://api.clixlo.com/js/form_embed.js"></script>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Contact);