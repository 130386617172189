import React, { Component } from "react";
import { Container, Row, Col, Input, Form, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Section extends Component {
  render() {
    const { t, isValidToken, inviteeName, companyName, inviteeEmail } = this.props;

    return (
      <React.Fragment>
        <section className="section bg-home" id="home">
          <div className="home-center">
            <div className="home-desc-center">
              <Container>
                <Row className="vertical-content">
                  <Col lg={7}>
                    <div>
                      <h1 className="text-white home-title mb-0">
                        {t('Join the logistics revolution: Manage your cargo intelligently with Logitruck AI!')}
                      </h1>
                      <p className="text-muted home-subtitle mt-4 mb-0">
                        {t('Remote control, real-time tracking, and more. Logitruck AI makes managing your cargo transportation easy with low-cost technology.')}
                      </p>
                      <div className="mt-4">
                        <Link to="#" className="btn btn-outline-white btn-round">
                          {t('Learn More')}
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col lg={5} className="mt-3">
                    <div className="home-registration-form mx-auto bg-white w-75 p-4">
                      {isValidToken ? (
                        <>
                          <h5 className="form-title mb-4 text-center fw-bold">
                            {`${t('Welcome')}, ${inviteeName}! ${t('You have been invited by')} ${companyName}.`}
                          </h5>
                          <Form className="registration-form">
                            <Label className="text-muted">{t('Name')}</Label>
                            <Input
                              type="text"
                              id="exampleInputName1"
                              className="form-control mb-2 registration-input-box"
                            />
                            <Label className="text-muted">{t('Email')}</Label>
                            <Input
                              type="email"
                              id="exampleInputEmail1"
                              className="form-control mb-2 registration-input-box"
                              defaultValue={inviteeEmail || ''}
                              readOnly={isValidToken}
                            />
                            <Label className="text-muted">{t('Password')}</Label>
                            <Input
                              type="password"
                              id="password1"
                              className="form-control mb-2 registration-input-box"
                            />
                            <button className="btn btn-primary w-100 mt-3 text-uppercase">
                              {t('Start')}
                            </button>
                          </Form>
                        </>
                      ) : (
                        <>
                          <h5 className="form-title mb-4 text-center fw-bold">
                            {t('Access to our exclusive LogiTruck AI community is by invitation only from an active member.')}
                          </h5>
                          <iframe
                            src="https://api.clixlo.com/widget/form/8HYonfYITlWapeSlRPBl"
                            style={{ width: "100%", height: "400px", border: "none", borderRadius: "4px" }}
                            id="inline-8HYonfYITlWapeSlRPBl"
                            data-layout="{'id':'INLINE'}"
                            data-trigger-type="alwaysShow"
                            data-trigger-value=""
                            data-activation-type="alwaysActivated"
                            data-activation-value=""
                            data-deactivation-type="neverDeactivate"
                            data-deactivation-value=""
                            data-form-name="Email Optin - Logitruck"
                            data-height="400"
                            data-layout-iframe-id="inline-8HYonfYITlWapeSlRPBl"
                            data-form-id="8HYonfYITlWapeSlRPBl"
                            title="Email Optin - Logitruck"
                          ></iframe>
                          <script src="https://api.clixlo.com/js/form_embed.js"></script>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Section);

