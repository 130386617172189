import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [
        {
          id: 1,
          image: "assets/images/team/member-1.jpg",
          name: "Carlos Saavedra",
          title: this.props.t("CEO & Co-Founder"),
          description: this.props.t("Carlos has over 20 years of experience in logistics and transportation, leading teams and innovative projects."),
        },
        {
          id: 2,
          image: "assets/images/team/member-3.jpg",
          name: "Juan Moreno",
          title: this.props.t("Director of Innovation & Co-Founder"),
          description: this.props.t("Juan has extensive experience in technological innovation for over 25 years."),
        },
        {
          id: 3,
          image: "assets/images/team/member-3.jpg",
          name: "Nora Vargas",
          title: this.props.t("Chief Financial Officer"),
          description: this.props.t("Nora has extensive experience in financial management and has worked in the industry for over 15 years."),
        },
        {
          id: 4,
          image: "assets/images/team/member-4.jpg",
          name: "Viviana Diaz",
          title: this.props.t("Chief Operations Officer"),
          description: this.props.t("Viviana is a project management professional with a focus on digital strategies and brand growth."),
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    const teamMembers = this.state.members.map((member) => (
      <Col md={6} lg={3} key={member.id}>
        <div className="team-box text-center mt-4">
          <img
            src={member.image}
            alt={member.name}
            className="img-fluid rounded-circle"
          />
          <div className="mt-3">
            <h5 className="team-name fw-bold">{member.name}</h5>
            <p className="team-title text-muted">{member.title}</p>
            <p className="team-description text-muted">{member.description}</p>
          </div>
        </div>
      </Col>
    ));

    return (
      <React.Fragment>
        <section className="section bg-light" id="team">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={8}>
                <i className="ti-user title-icon text-muted"></i>
                <h3 className="title">
                  {t('Our')} <span className="fw-bold">{t('Team')}</span>
                </h3>
                <p className="text-muted mt-3 title-subtitle mx-auto">
                  {t('Meet the talented team behind Logitruck AI.')}
                </p>
              </Col>
            </Row>

            <Row className="mt-5">
              {teamMembers}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Team);