import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

class Faq extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <section className="section" id="faq">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={12}>
                <i className="ti-comments title-icon text-muted"></i>
                <h3 className="title">{t('Support Center')}</h3>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={6}>
                <div className="ps-4 pe-4">
                  <div className="mt-4">
                    <div className="">
                      <h5 className="mb-0 f-18 font-weight-600">{t('Q. How can I start using Logitruck AI?')}</h5>
                    </div>
                    <div className="mt-3">
                      <p className="text-muted">
                        {t('You can start using Logitruck AI by accepting the invitation from your partner company and creating an account.')}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="">
                      <h5 className="mb-0 f-18 font-weight-600">{t('Q. What features does Logitruck AI offer?')}</h5>
                    </div>
                    <div className="mt-3">
                      <p className="text-muted">
                        {t('Logitruck AI offers features such as electronic tickets, real-time tracking, route optimization, and more to manage your cargo logistics efficiently.')}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="">
                      <h5 className="mb-0 f-18 font-weight-600">{t('Q. Is Logitruck AI free to use?')}</h5>
                    </div>
                    <div className="mt-3">
                      <p className="text-muted">
                        {t('Logitruck AI is free to use for businesses invited by partner companies. For additional services, you may need to subscribe.')}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="ps-4 pe-4">
                  <div className="mt-4">
                    <div className="">
                      <h5 className="mb-0 f-18 font-weight-600">{t('Q. How does real-time tracking work?')}</h5>
                    </div>
                    <div className="mt-3">
                      <p className="text-muted">
                        {t('Logitruck AI uses GPS technology to provide real-time updates on the location and status of your shipments.')}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="">
                      <h5 className="mb-0 f-18 font-weight-600">{t('Q. Can I optimize routes with Logitruck AI?')}</h5>
                    </div>
                    <div className="mt-3">
                      <p className="text-muted">
                        {t('Yes, Logitruck AI offers route optimization features to help you plan the most efficient routes for your fleet.')}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="">
                      <h5 className="mb-0 f-18 font-weight-600">{t('Q. How can I contact support?')}</h5>
                    </div>
                    <div className="mt-3">
                      <p className="text-muted">
                        {t('You can contact Logitruck AI support via chat or schedule an appointment through the contact page.')}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Faq);