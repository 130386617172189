import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Link } from "react-router-dom";


class Section extends Component {
  render() {
    return (
      <section id="home" className="bg-home-slider position-relative">
        <div>
          <Carousel className="carousel slide text-center home-carousel" id="carouselExampleIndicators"
            showThumbs={false} showStatus={false} showArrows={false} autoPlay infiniteLoop interval={2000} showIndicators={false} animationHandler="fade">
            <div className="item pb-5">
              <div className="bg-overlay"></div>
              <Container>
                <Row className="justify-content-center">
                  <Col sm={10}>
                    <h1 className="text-white home-title mb-0">
                      We love make things amazing and simple
                    </h1>
                    <p className="text-white-50 home-subtitle-center home-subtitle mt-4 mb-0 mx-auto">It is a long established fact that a reader will be of a page when established fact looking at its layout.</p>
                    <div className="mt-4 mb-4">
                      <Link to="#" className="btn btn-outline-white btn-round">Read More</Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="item pb-5">
              <div className="bg-overlay"></div>
              <Container>
                <Row className="justify-content-center">
                  <Col sm={10}>
                    <h1 className="text-white home-title mb-0">
                      Create amazing landing page with Globing
                    </h1>
                    <p className="text-white-50 home-subtitle-center home-subtitle mt-4 mb-0 mx-auto">It is a long established fact that a reader will be of a page when established fact looking at its layout.</p>
                    <div className="mt-4 mb-4">
                      <Link to="#" className="btn btn-outline-white btn-round">Read More</Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="item pb-5">
              <div className="bg-overlay"></div>
              <Container>
                <Row className="justify-content-center">
                  <Col sm={10}>
                    <h1 className="text-white home-title mb-0">
                      Perfact solution for small businesses
                    </h1>
                    <p className="text-white-50 home-subtitle-center home-subtitle mt-4 mb-0 mx-auto">It is a long established fact that a reader will be of a page when established fact looking at its layout.</p>
                    <div className="mt-4 mb-4">
                      <Link to="#" className="btn btn-outline-white btn-round">Read More</Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel>
        </div>
      </section>
    )
  }
}

export default Section;
