import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore methods

// Importing Section
import NavbarPage from "../../component/Navbar/NavBar";
import Section from "./Section";
import Services from "../../component/Services";
import Feature from "../../component/Feature";
import Clients from "../../component/Clients";
import Team from "../../component/Team";
import Cta from "../../component/Cta";
import Faq from "../../component/Faq";
import Pricing from "../../component/Pricing";
import Contact from "../../component/Contact";
import Footer from "../../component/Footer/Footer";

class Layout1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "services", navheading: "Services" },
        { id: 3, idnm: "features", navheading: "Features" },
        { id: 4, idnm: "testi", navheading: "Client" },
        { id: 5, idnm: "team", navheading: "Team" },
        { id: 6, idnm: "faq", navheading: "FAQ" },
        { id: 7, idnm: "pricing", navheading: "Pricing" },
        { id: 8, idnm: "contact", navheading: "Contact" },
      ],
      pos: document.documentElement.scrollTop,
      navClass: "",
      companyName: "",
      inviteeName: "",
      inviteeEmail: "",
      isValidToken: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
    this.checkInvitationToken();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    const scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky" });
    } else {
      this.setState({ navClass: "" });
    }
  };

  checkInvitationToken = async () => {
    const token = new URLSearchParams(this.props.location.search).get('token');
    const sender = new URLSearchParams(this.props.location.search).get('sender');
    console.log(token, sender)
    if (token && sender) {
      try {
        const { firestore } = getFirebaseBackend();
        const docRef = doc(firestore, `partner_graph/${sender}/inbound_user_token/${token}`);
        const invitationDoc = await getDoc(docRef);
        if (invitationDoc.exists()) {
          const invitationData = invitationDoc.data();
          if (invitationData.isValidtoken) {
            this.setState({
              companyName: invitationData.companyName,
              inviteeName: invitationData.companyGuess,
              inviteeEmail: invitationData.companyGuessMail,
              isValidToken: true,
            });
          } else {
            console.log("Invalid token");
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error validating token: ", error);
      }
    } else {
      console.log("Token or sender is missing");
    }
  };

  render() {
    const { companyName, inviteeName, inviteeEmail, isValidToken } = this.state;

    return (
      <React.Fragment>
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
        />
        {/* Pasar información del token a Section */}
        <Section
          isValidToken={isValidToken}
          inviteeName={inviteeName}
          companyName={companyName}
          inviteeEmail={inviteeEmail}
        />
        {/* Importing Services */}
        <Services />
        {/* Importing Feature */}
        <Feature />
        {/* Importing Clients */}
        <Clients />
        {/* Importing Team */}
        <Team />
        {/* Importing Faq */}
        <Faq />
        {/* Importing Cta */}
        <Cta />
        {/* Importing Pricing */}
        <Pricing />
        {/* Importing Contact */}
        <Contact />
        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

// Usando un componente de orden superior para acceder a location y pasar como prop
function Layout1WithLocation() {
  const location = useLocation();
  return <Layout1 location={location} />;
}

export default Layout1WithLocation;