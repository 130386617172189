import React, { Component } from "react";
import {
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";

import { Link } from "react-router-dom";
import ScrollspyNav from "./Scrollspy";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import { withTranslation } from "react-i18next";
import withRouter from '../../common/withRouter';

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }
  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };
  render() {
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    const { t } = this.props;
    return (
      <React.Fragment>
        <nav
          className={"navbar navbar-expand-lg navbar-custom sticky sticky-dark fixed-top " + this.props.navClass}
          id="navbar"
        >
          <Container>
            <Link to="#" className="navbar-brand">
              LogiTruck
            </Link>
            <NavbarToggler onClick={this.toggle}>
              <span className="ti-menu"></span>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className=" navbar-collapse"
            >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav className="navbar-nav mx-auto" id="navbar-navlist">
                  {this.props.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink
                        className={item.navheading === "Home" ? "active" : ""}
                        href={"#" + item.idnm}
                      >
                        {t(item.navheading)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <div>
                  <ul className="text-end list-unstyled list-inline mb-0 nav-social">
                    <li className="list-inline-item text-white nav-number">
                      <i className="ti-mobile"></i> <span>+1 234 567 </span>
                    </li>{" "}
                    <li className="list-inline-item">
                      <LanguageDropdown />
                    </li>
                  </ul>
                </div>
              </ScrollspyNav>
            </Collapse>
          </Container>
        </nav>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(NavbarPage));