import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

let firebaseBackend;

const initFirebaseBackend = (config) => {
  if (!firebaseBackend) {
    const app = initializeApp(config);
    firebaseBackend = {
      auth: getAuth(app),
      firestore: getFirestore(app),
      storage: getStorage(app),
    };
  }
};

const getFirebaseBackend = () => {
  if (!firebaseBackend) {
    throw new Error("Firebase backend not initialized. Call initFirebaseBackend(config) first.");
  }else{
    console.log('iniciado')
  }
  return firebaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };