import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingData: [
        {
          price: "$0",
          bandwidth: "1GB",
          onlinespace: "50MB",
          support: this.props.t("No"),
          domain: 1,
          description: this.props.t("Free for invited users from partner companies."),
          isFree: true,
        },
        {
          price: "$29",
          bandwidth: "2GB",
          onlinespace: "500MB",
          support: this.props.t("Yes"),
          domain: 5,
          description: this.props.t("Access to all features with a monthly subscription."),
          isActive: true,
        },
        {
          price: "$59",
          bandwidth: "4GB",
          onlinespace: "1 GB",
          support: this.props.t("Yes"),
          domain: 10,
          description: this.props.t("Premium plan with extended features and support."),
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    const PricingBox = this.state.pricingData.map((item, key) => (
      <Col lg={4} key={key}>
        <div className={item.isActive ? "bg-white price-box active text-center mt-3" : "bg-white price-box text-center mt-3"}>
          <div className="plan-price fw-bold">
            <h1 className="mb-0 fw-bold">{item.price}</h1>
            <p className="mb-0">{t('Per Month')}</p>
          </div>
          <div className="plan-features text-muted mt-5 mb-5">
            <p>{t('Bandwidth')}: <b className="text-primary">{item.bandwidth}</b></p>
            <p>{t('Online Space')}: {item.onlinespace}</p>
            <p>{t('Support')}: {item.support}</p>
            <p>{item.domain} {t('Domain')}</p>
            <p className="mb-0">{t('No Hidden Fees')}</p>
            <p className="mt-2">{item.description}</p>
          </div>
          <div>
            {item.isFree ? (
              <Link to="/register" className="btn btn-primary btn-round">
                {t('Join Now')}
              </Link>
            ) : (
              <Link to="/contact" className="btn btn-primary btn-round">
                {t('Contact Us')}
              </Link>
            )}
          </div>
        </div>
      </Col>
    ));

    return (
      <React.Fragment>
        <section className="section bg-light" id="pricing">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg={12}>
                <i className="ti-package title-icon text-muted"></i>
                <h3 className="title">
                  {t('Our')} <span className="fw-bold">{t('Pricing')}</span>
                </h3>
                
              </Col>
            </Row>
            <Row className="mt-5">{PricingBox}</Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Pricing);