import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

class Cta extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <h3 className="mb-4">
                    {t('Do you have more questions or need help?')}
                  </h3>
                  <div className="">
                    <Link to="/contact" className="btn btn-round btn-primary">
                      {t('Contact Us')}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Cta);